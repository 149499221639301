/**
 *  +----------------------------------------------------------------------
 *  | Copyright notion All rights reserved.
 *  +----------------------------------------------------------------------
 *  | 未经许可不能去掉notion相关版权
 *  +----------------------------------------------------------------------
 *  | Author: 诺行
 *  +----------------------------------------------------------------------
 */
import request from './../request.js'

export function refreshLiuliang(params) { //get网卡分销列表详情
    return request({
        url: 'card/refreshFlow.do?',
        method: 'post',
        data: params
    })
}

export function findCardInfo(params) { //get网卡分销列表详情
    return request({
        url: 'card/findCardInfo.do?',
        method: 'post',
        data: params
    })
}

export function findCardInfoAll(params) { //get网卡分销列表详情
    return request({
        url: 'card/findCardInfoAll.do?',
        method: 'post',
        data: params
    })
}
export function isRealName(params) {
    return request({
        url: 'card/isRealName.do',
        method: 'post',
        data: params
    })
}

export function statusModify(params) { //转正使用
    return request({
        url: 'card/statusModify.do?',
        method: 'post',
        data: params
    })
}
export function intelliDiagnose(params) { //智能诊断
    return request({
        url: 'card/intelliDiagnose.do?',
        method: 'post',
        data: params
    })
}

export function activate_(params) { //激活
    return request({
        url: 'card/activate.do?',
        method: 'post',
        data: params
    })
}

export function cardRecovery_(params) { //保号复机
    return request({
        url: 'card/cardRecovery.do?',
        method: 'post',
        data: params
    })
}

export function seperationRecovery(params) { //机卡分离复机  
    return request({
        url: 'card/seperationRecovery.do?',
        method: 'post',
        data: params
    })
}
export function cardStop_(params) { //保号停机
    return request({
        url: 'card/cardStop.do?',
        method: 'post',
        data: params
    })
}

export function limitStopRecovery_(params) { //达量断网复机
    return request({
        url: 'card/limitStopRecovery.do?',
        method: 'post',
        data: params
    })
}
export function setThreshold_(params) { //更新阈值
    return request({
        url: 'card/setThreshold.do?',
        method: 'post',
        data: params
    })
}
export function deviceCardBound_(params) { //机卡绑定
    return request({
        url: 'card/deviceCardBound.do?',
        method: 'post',
        data: params
    })
}

export function tamperRecovery_(params) { //窜卡复机
    return request({
        url: 'card/tamperRecovery.do?',
        method: 'post',
        data: params
    })
}

export function noLoopcard_(params) { //不轮询网卡
    return request({
        url: 'card/addNoloopCard.do?',
        method: 'post',
        data: params
    })
}

export function allowRealname_(params) { //允许实名
    return request({
        url: 'card/allowRealname.do?',
        method: 'post',
        data: params
    })
}

export function openVoice_(params) { //开通语音(GD)
    return request({
        url: 'card/openVoice.do?',
        method: 'post',
        data: params
    })
}

export function delOnlyLoop_(params) { //删除仅轮询
    return request({
        url: 'card/delOnlyLoop.do?',
        method: 'post',
        data: params
    })
}


//addpackage.vue
export function getPackageAll(params) { //确认套餐充值弹框前的接口
    return request({
        url: 'meal/setmeal/getPackageAll.do?',
        method: 'post',
        data: params
    })
}

export function mealRecharge(params) {
    return request({
        url: 'card/mealRecharge.do?',
        method: 'post',
        data: params
    })
}

export function diagnoseKf(params) {
    return request({
        url: 'card/diagnoseKf.do?',
        method: 'post',
        data: params
    })
}

//limitStop.vue
export function limitStop_(params) {
    return request({
        url: 'card/limitStop.do?',
        method: 'post',
        data: params
    })
}

//flowDetail.vue
export function listFlowDetail(params) {
    return request({
        url: 'card/listFlowDetail.do?',
        method: 'post',
        data: params
    })
}
//flowDetail.vue
export function listFlowDetailTotal(params) {
    return request({
        url: '/card/cardMonthFlowDetail.do?',
        method: 'post',
        data: params,
    })
}

//flowDetail.vue
export function deleteCardMonthFlowDetail(params) {
    return request({
        url: '/card/deleteCardMonthFlowDetail.do? ',
        method: 'post',
        data: params,
    })
}
//flowDetail.vue
export function deleteCardMonthFlowDetailOne(params) {
    return request({
        url: '/card/deleteUsedFlowDetail.do?',
        method: 'post',
        data: params,
    })
}
/*更新导出接口 */
export function exportFlowDetail(params) {
    return request({
        url: 'card/exportFlowDetailSync.do?',
        method: 'post',
        data: params
    })
}

//updateExpir.vue
export function listCardLife(params) {
    return request({
        url: 'card/listCardLife.do?',
        method: 'post',
        data: params
    })
}

export function updateOrDeleteCardLife(params) {
    return request({
        url: 'card/updateOrDeleteCardLife.do?',
        method: 'post',
        data: params
    })
}
export function delAll(params) {
    return request({
        url: 'card/delAll.do?',
        method: 'post',
        data: params
    })
}

//toNewCard.vue
export function getSetMealByCategory(params) {
    return request({
        url: 'meal/setmeal/getSetMealByCategory.do?',
        method: 'post',
        data: params
    })
}
export function toNewCard(params) {
    return request({
        url: 'card/oldcardchangerecord/toNewCard.do?',
        method: 'post',
        data: params
    })
}

//tingfujiRecord.vue
export function stopOrStartRecord(params) {
    return request({
        url: 'record/stopOrStartRecord/list.do?',
        method: 'post',
        data: params
    })
}

//tingfujiDetail.vue
export function viewDetail(params) {
    return request({
        url: 'record/stopOrStartRecord/viewDetail.do?',
        method: 'post',
        data: params
    })
}

//orderRecord.vue
export function orderRecord(params) {
    return request({
        url: 'order/agentwechatorder/agentWechatOrderAndCarMealOrder.do?',
        method: 'post',
        data: params
    })
}

export function add4remark(params) {
    return request({
        url: 'order/agentwechatorder/add4remark.do?',
        method: 'post',
        data: params
    })
}

export function refundMoney(params) {
    return request({
        url: 'orderRefund/creatApply.do?',
        method: 'post',
        data: params
    })
}


//modifyFlow.vue
export function modifyFlow(params) {
    return request({
        url: 'card/modifyFlowAndAddRecord.do?',
        method: 'post',
        data: params
    })
}
//modifyFlow.vue
export function modifyFlowOne(params) {
    return request({
        url: '/card/modifyVirtualFlowAndAddRecord.do?',
        method: 'post',
        data: params
    })
}
//remarkList.vue
export function getAccountList() {
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post'
    })
}
export function getRemarkByIccid(params) {
    return request({
        url: 'cardRemark/getByIccid.do?',
        method: 'post',
        data: params
    })
}
export function cardRemarkList(params) {
    return request({
        url: 'cardRemark/list.do?',
        method: 'post',
        data: params
    })
}
export function cardRemarkUpdate(params) {
    return request({
        url: 'cardRemark/update.do?',
        method: 'post',
        data: params
    })
}
export function cardRemarkDel(params) {
    return request({
        url: 'cardRemark/del.do?',
        method: 'post',
        data: params
    })
}
export function cardRemarkExport(params) {
    return request({
        url: 'cardRemark/exportSync.do?',
        method: 'post',
        data: params
    })
}

//切换虚量
export function changeVirtualStrategy(params) {
    return request({
        url: 'card/changeVirtualStrategy.do?',
        method: 'post',
        data: params
    })
}
//单卡闸值设置
export function singleCardStop(params) {
    return request({
        url: 'chargeGroup/singleCardStop.do?',
        method: 'post',
        data: params
    })
}
export function findSingleCardStopByIccid(params) {
    return request({
        url: 'chargeGroup/findSingleCardStopByIccid.do?',
        method: 'post',
        data: params
    })
}
//套餐提前生效
export function mealAdvanceLife(params) {
    return request({
        url: '/card/advanceLife.do?',
        method: 'post',
        data: params
    })
}
//取消单独断网
export function cancelDxDisconnected(params) {
    return request({
        url: '/card/cancelDxDisconnected.do?',
        method: 'post',
        data: params
    })
}

// 实名解绑
export function unboundRealName(params) {
    return request({
        url: '/card/unbound.do?',
        method: 'post',
        data: params
    })
}

// 是否显示立即生效
export function getButtonResource(params) {
    return request({
        url: '/card/getButtonResource.do?',
        method: 'post',
        data: params
    })
}

// 退货清零接口
export function returnToZero(params) {
    return request({
        url: '/card/rejectClear.do?',
        method: 'post',
        data: params
    })
}

// 退货清零接口
export function forceActive(params) {
    return request({
        url: '/card/forceActive.do?',
        method: 'post',
        data: params
    })
}


// 清除插拔卡
export function clearPluginCards(params) {
    return request({
        url: '/card/removeChaba.do?',
        method: 'post',
        data: params
    })
}


export function extraStatus(params) {
    return request({
        url: '/card/extraStatus.do?',
        method: 'get',
        params:params,
    })
}

export function clearHistory(params) {
    return request({
        url: '/card/clearCardCache.do?',
        method: 'post',
        data: params,
    })
}
export function getOperateList(params) {
    return request({
        url: '/system/config/get.do?',
        method: 'post',
        data: params,
    })
}
export function unbindVirtualCard(params) {
    return request({
        url: '/card/unbindVirtualCard.do?',
        method: 'post',
        data: params,
    })
}
export function updateNotBind(params) {
    return request({
        url: '/card/updateNotBind.do?',
        method: 'post',
        data: params,
    })
}
export function deviceInfo(params) {
    return request({
        url: '/iot/device/info.do?',
        method: 'get',
        params: params,
    })
}

export function getStopAheadDays(params) {
    return request({
        url: '/card/getStopAheadDays.do?',
        method: 'get',
        params: params,
    })
}


export function clearStopRecord(params) {
    return request({
        url: '/card/deleteCardStop.do?',
        method: 'post',
        data: params
    })
}











